import React, { useState, useRef, useCallback } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { Fade } from 'react-reveal'
import useOnClickOutside from '@src/hooks/useOnClickOutside'
import { useEffect } from 'react'

const ROOT_CLASS = 'input-with-dropdown'

const InputWithDropdown = ({
  label = 'Missing label',
  icon,
  children,
  forceClose,
  active,
  className
}) => {
  const [rawIsOpen, setRawIsOpen] = useState(false)
  const ref = useRef()
  const close = useCallback(() => rawIsOpen && setRawIsOpen(false), [
    rawIsOpen,
    setRawIsOpen
  ])
  useOnClickOutside(ref, close)

  const open = rawIsOpen && !forceClose

  return (
    <div ref={ref} className={className}>
      <button
        onClick={() => setRawIsOpen(!rawIsOpen)}
        className={cn(
          ROOT_CLASS,'no-style prod-title mb-0',
          active ? `${ROOT_CLASS}--active` : ''
        )}
      >
        <p
          className={cn(
            `${ROOT_CLASS}__label mb-0`,
            open ? `${ROOT_CLASS}__label--open` : ''
          )}
        >
          {label}
        </p>
        <FontAwesomeIcon
          className={cn(
            `${ROOT_CLASS}__icon`,
            open && `${ROOT_CLASS}__icon--rotate`
          )}
          icon={faAngleDown}
        />
      </button>
      <div className='position-relative'>
        <Fade reverse when={open} duration={100} top distance='15px'>
          <div
            className={cn('dropdown', {
              'dropdown--open': open
            })}
          >
            {children}
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default InputWithDropdown
